import { REACT_APP_AMPLITUDE_API_KEY } from 'lily-env';
import * as amplitude from '@amplitude/analytics-browser';

/**
 * @description Amplitude API key
 */
const amplitudeApiKey: string = REACT_APP_AMPLITUDE_API_KEY;

/**
 * @description Options for Amplitude initialization
 */
const options = {
    autocapture: false,
};

/**
 * @description Function to initialize Amplitude
 * @param {string} userId
 */
export const initializeAmplitude = (userId?: string) => {
    if (userId) {
        amplitude.init(amplitudeApiKey, userId);
    } else {
        amplitude.init(amplitudeApiKey, options);
    }
};

/**
 * @description Function to track an event
 * @param {string} eventName
 * @param {object} properties
 */
export const trackEvent = (eventName: string, properties?: object) => {
    amplitude.track(eventName, properties);
};

/**
 * @description Function to create an instance and initialize it
 * @param {string} instanceName
 * @returns {amplitude.Types.BrowserClient} instance
 */
export const createAmplitudeInstance = (instanceName?: string) => {
    const instance = amplitude.createInstance();
    if (instanceName) {
        instance.init(amplitudeApiKey, { instanceName });
    } else {
        instance.init(amplitudeApiKey);
    }
    return instance;
};

/**
 * @description Function to initialize Amplitude and track a button click event
 */
export const initializeAndTrackEvent = () => {
    amplitude.init(amplitudeApiKey).promise.then(() => {
        amplitude.track('Button Clicked');
        amplitude.flush();
    });
};

/**
 * @description Function to track user session
 * @param {string} userId - User ID for the session
 */
export const trackSession = (userId: string) => {
    // Initialize with user ID if not already initialized
    trackEvent('Session_Start', { timestamp: new Date().toISOString() });
    initializeAmplitude(userId);
    console.log("session start", userId);
};

/**
 * @description Function to destroy the Amplitude session and clean up
 */
export const destroyAmplitudeSession = (userId: string) => {
    // Set the user ID to null
    // amplitude.setUserId('');
    trackEvent('Session_End', { timestamp: new Date().toISOString() });
    initializeAmplitude(userId);
};
