const trimSlashes = (str: string) => str.replace(/^\/+|\/+$/g, '');

/**
 * Gets an environment variable for an env-agnostic build.
 *
 * Docs say:
 *
 * > Environment variables must be referenced as e.g. process.env.PUBLISHABLE_KEY, not const { PUBLISHABLE_KEY } = process.env.
 *
 * However, in order to achieve an env-agnostic build, we do the opposite: we never introduce an environment variable into the
 * bundled JS. To inject env variables into the code we use the public-env.js file which is generated at deploy time.
 *
 * Important: Never put a sensitive secret in the public-env.js file, as anyone in the world can download that file.
 *
 * @param {string} key - Key of the env variable to get.
 * @param {string} $default - Default value if no key is defined.
 */
const getEnv = (key: string, $default = '') => {
    if (typeof window === 'undefined') {
        throw new Error('Cannot get env variable, not running on the browser.');
    }

    const { publicEnv = {} } = window as any;

    if (!publicEnv) throw new Error(`Cannot get property ${key}. Global object 'publicEnv' is not defined.`);

    if (publicEnv.hasOwnProperty(key)) return publicEnv[key];

    if (typeof $default !== 'undefined') {
        return $default;
    }

    throw new Error(`Property '${key}' does not exist in 'publicEnv' object and no default value was provided.`);
};

export const REACT_APP_LAUNCH_DARKLY_CLIENT = getEnv('REACT_APP_LAUNCH_DARKLY_CLIENT', '');
export const REACT_APP_MIDDLEWARE_HOSTNAME = getEnv('REACT_APP_MIDDLEWARE_HOSTNAME', '');
export const REACT_APP_MIDDLEWARE_API = getEnv('REACT_APP_MIDDLEWARE_API', '');
export const REACT_APP_PROXY_PRODUCT = getEnv('REACT_APP_PROXY_PRODUCT', '');
export const REACT_APP_APP_VERSION = getEnv('REACT_APP_APP_VERSION', '');
export const REACT_APP_SEGMENT_KEY = getEnv('REACT_APP_SEGMENT_KEY', 'none'); // TODO: Don't use none, instead make the unit tests able to setup this value at runtime.
export const REACT_APP_CRYPTO = getEnv('REACT_APP_CRYPTO', '');
export const REACT_APP_ENV_NAME = getEnv('REACT_APP_ENV_NAME', '');
export const REACT_APP_APPLICATION_ID_URI = getEnv('REACT_APP_APPLICATION_ID_URI', '');
export const REACT_APP_AZURE_CLIENT_ID = getEnv('REACT_APP_AZURE_CLIENT_ID', '');
export const REACT_APP_AZURE_TENANT_ID = getEnv('REACT_APP_AZURE_TENANT_ID', '');
export const REACT_APP_AZURE_TENANT_LOGIN = getEnv('REACT_APP_AZURE_TENANT_LOGIN', '');
export const REACT_APP_AZURE_SIGN_IN = getEnv('REACT_APP_AZURE_SIGN_IN', '');
export const REACT_APP_AZURE_FORGOT_PASSWORD = getEnv('REACT_APP_AZURE_FORGOT_PASSWORD', '');
export const REACT_APP_AZURE_TENANT_DOMAIN = getEnv('REACT_APP_AZURE_TENANT_DOMAIN', '');
export const REACT_APP_AZURE_EDIT_PROFILE = getEnv('REACT_APP_AZURE_EDIT_PROFILE', '');
export const REACT_APP_AZURE_READ = getEnv('REACT_APP_AZURE_READ', '');
export const REACT_APP_AZURE_INTERNAL_CLIENT_ID = getEnv('REACT_APP_AZURE_INTERNAL_CLIENT_ID', '');
export const REACT_APP_AZURE_INTERNAL_TENANT_ID = getEnv('REACT_APP_AZURE_INTERNAL_TENANT_ID', '');
export const REACT_APP_AZURE_INTERNAL_APPLICATION_ID_URI = getEnv('REACT_APP_AZURE_INTERNAL_APPLICATION_ID_URI', '');
export const REACT_APP_AZURE_INTERNAL_REDIRECT_URI = getEnv('REACT_APP_AZURE_INTERNAL_REDIRECT_URI', '');
export const REACT_APP_AZURE_INTERNAL_READ = getEnv('REACT_APP_AZURE_INTERNAL_READ', '');
export const PI_ANALYTICS_SUBSIDIARIES = getEnv('PI_ANALYTICS_SUBSIDIARIES', '');
export const REACT_APP_AMPLITUDE_API_KEY = getEnv('REACT_APP_AMPLITUDE_API_KEY', '');

// TODO: Trim all slashes of all 'host' env vars.
